import { SocialLogin } from '@capgo/capacitor-social-login';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useAppDispatch, useAppSelector, useNotify } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { logoutUser as logoutUserStoreAction } from '../store';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const usedLoginType = useAppSelector((state) => state.user.session?.loginType);

  const logout = useCallback(
    async (showExpiredSessionNotification: boolean = false) => {
      // await logoutUserRequest();

      // INFO: Logout from Google to prevent auto-login and add available to change account
      if (usedLoginType === 'google' && (await SocialLogin.isLoggedIn({ provider: 'google' })))
        await SocialLogin.logout({
          provider: 'google',
        });

      if (usedLoginType === 'apple' && (await SocialLogin.isLoggedIn({ provider: 'apple' })))
        await SocialLogin.logout({
          provider: 'google',
        });

      queryClient.clear();
      queryClient.removeQueries();
      dispatch(logoutUserStoreAction());

      if (showExpiredSessionNotification) notify('warning', t('common:yourSessionHasExpired'));

      // Then ProtectedRoute will redirect to login page if session is null
    },
    [dispatch, notify, queryClient, t, usedLoginType],
  );

  return { logout };
};
