import { isNative } from '@/utils';

import { GoogleOAuthProvider as nativeModule } from './GoogleOAuthProvider.native';
import { GoogleOAuthProvider as webModule } from './GoogleOAuthProvider.web';
import { GoogleOAuthProviderProps } from './types';

export const GoogleOAuthProvider = (props: GoogleOAuthProviderProps) => {
  if (isNative) return nativeModule(props);
  else return webModule(props);
};
