import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

import GoogleLogo from '@/assets/icons/GoogleLogo.svg';
import AppleLogo from '@/assets/icons/AppleLogo.svg';
import { Text } from '@/components/elements';
import { TranslationKeys } from '@/translation';

import { SSOButtonContainer } from './styled';

interface SSOButtonProps {
  provider: 'google' | 'apple';
  type: 'login' | 'registration';
  onClick: VoidFunction | undefined;
  disabled?: boolean;
}

export const SSOButton = ({ provider, type, onClick, disabled }: SSOButtonProps) => {
  const { palette } = useTheme();

  const label = useMemo<TranslationKeys>(() => {
    switch (provider) {
      default:
      case 'google':
        return type === 'login' ? 'user:signInUsingGoogle' : 'user:signUpUsingGoogle';
      case 'apple':
        return type === 'login' ? 'user:signInUsingApple' : 'user:signUpUsingApple';
    }
  }, [provider, type]);

  return (
    <SSOButtonContainer onClick={onClick} disabled={disabled}>
      <Stack direction="row" width="100%" alignItems="center" justifyContent="center">
        {provider === 'google' && <GoogleLogo height={20} />}
        {provider === 'apple' && <AppleLogo height={20} />}
        <Box ml={3}>
          <Text
            variant="bodyMedium"
            fontWeight={500}
            translationKey={label}
            width="100%"
            sx={{ color: palette.common.black }}
          />
        </Box>
      </Stack>
    </SSOButtonContainer>
  );
};
