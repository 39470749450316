import { Box, Button, Stack } from '@mui/material';

import { Text } from '@/components/elements';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { ChatIcon } from './ChatIcon';

interface Props {
  hideHistoryButton?: boolean;
  shortTitle?: boolean;
}

export const AssistantChatHeader = ({ shortTitle, hideHistoryButton }: Props) => {
  const { t } = useAppTranslation();

  const titleKey = shortTitle ? 'chat:assistant' : 'chat:assistantChat';

  return (
    <Box display="flex" flexDirection={[shortTitle ? 'row' : 'column', 'row']} justifyContent="flex-start">
      <Stack direction={[shortTitle ? 'row' : 'column', 'row']} spacing={2} justifyContent="flex-start">
        <Stack direction="row" spacing={4} alignItems="center">
          {!shortTitle && <ChatIcon />}
          <Text variant="headlineSmall" translationKey={titleKey} noWrap />
        </Stack>
      </Stack>

      <Box display="flex" flex={1} justifyContent="flex-end" pl={[shortTitle ? 4 : 9, 4]}>
        {!hideHistoryButton && (
          <Button variant="text" color="primary" href={Pages.assistantChat} sx={{ whiteSpace: 'nowrap' }}>
            {t('chat:chatHistory')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
