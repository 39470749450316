import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Suspense, lazy } from 'react';

import { Pages } from '@/navigation';
import { isNative } from '@/utils';

import { ConditionalWrapper } from './ConditionalWrapper';

const BlackLogo = lazy(() => import('@/assets/logo/logo-horizontal-black.svg'));
const WhiteLogo = lazy(() => import('@/assets/logo/logo-horizontal-white.svg'));

interface LogoProps {
  linkToHome?: boolean;
  linkToLandingPageOnWeb?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const sizes = {
  small: 25,
  medium: 40,
  large: 50,
};

export const Logo = ({ size = 'medium', linkToHome, linkToLandingPageOnWeb }: LogoProps) => {
  const { palette } = useTheme();
  const isLightMode = palette.mode === 'light';

  return (
    <ConditionalWrapper
      condition={!!linkToHome || (!!linkToLandingPageOnWeb && !isNative)}
      wrapper={(children) => (
        <Link display="block" href={linkToHome ? Pages.home : Pages.landing}>
          {children}
        </Link>
      )}
    >
      <Suspense>
        {isLightMode ? (
          <BlackLogo height={sizes[size] + 'px'} width={sizes[size] * 6.31 + 'px'} />
        ) : (
          <WhiteLogo height={sizes[size] + 'px'} width={sizes[size] * 6.31 + 'px'} />
        )}
      </Suspense>
    </ConditionalWrapper>
  );
};
