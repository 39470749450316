import { SocialLogin } from '@capgo/capacitor-social-login';
import { useState } from 'react';

import { useAppDispatch, useNotify } from '@/hooks';
import { captureError, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { ssoLogin } from '../../api';
import { setSession } from '../../store';

import { UseSSOLogin } from './types';

export const useSSOLogin: UseSSOLogin = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { notify } = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const genericFailure = (provider: 'google' | 'apple', error: unknown) => {
    notify('error', t('user:ssoLoginFailed'));
    captureError(new Error(`${provider} SSO login failed: ${JSON.stringify(error)}`));
    setIsLoading(false);
  };

  const loginUsingGoogle = async () => {
    setIsLoading(true);
    try {
      const userObject = await SocialLogin.login<'google'>({
        provider: 'google',
        options: {
          scopes: ['profile', 'email'],
        },
      });
      if (userObject.result.responseType !== 'online')
        throw new Error('Google login failed - response type is not online');

      const accessToken = userObject.result.accessToken?.token;

      if (!accessToken) throw new Error('Google login failed - no access token');

      const tokens = await ssoLogin({
        type: 'google',
        payload: { accessToken },
      });

      trackEvent.user.login('google');
      dispatch(setSession({ ...tokens, loginType: 'google' }));
      setIsLoading(false);
    } catch (error) {
      genericFailure('google', error);
    }
  };

  const loginUsingApple = async () => {
    setIsLoading(true);
    try {
      const userObject = await SocialLogin.login<'apple'>({
        provider: 'apple',
        options: {
          scopes: ['name', 'email'],
        },
      });

      const accessToken = userObject.result.accessToken?.token;
      const idToken = userObject.result.idToken;

      if (!accessToken || !idToken) throw new Error('Apple login failed - no access token');

      const tokens = await ssoLogin({
        type: 'apple',
        payload: {
          code: idToken,
          type: 'apple_mobile',
        },
      });

      trackEvent.user.login('apple');
      dispatch(setSession({ ...tokens, loginType: 'apple' }));
      setIsLoading(false);
    } catch (error) {
      genericFailure('apple', error);
    }
  };

  return {
    loginUsingGoogle,
    loginUsingApple,
    isLoading,
  };
};
