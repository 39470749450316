import { isNative } from '@/utils';

import { AppleOAuthProvider as nativeModule } from './AppleOAuthProvider.native';
import { AppleOAuthProvider as webModule } from './AppleOAuthProvider.web';
import { AppleOAuthProviderProps } from './types';

export const AppleOAuthProvider = (props: AppleOAuthProviderProps) => {
  if (isNative) return nativeModule(props);
  else return webModule(props);
};
