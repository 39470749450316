import { BackButtonListenerEvent, App as CapacitorApp } from '@capacitor/app';
import { Box, Container } from '@mui/material';
import { useEffect } from 'react';

import { SwipeBackPlugin } from '@/capacitorPlugins/SwipeBack';
import { BackButton, BackToHomePageButton, Header } from '@/components/elements';
import { isIos, isNative } from '@/utils';

import { SafeAreaTopBox } from './SafeAreaTopBox';

interface Props {
  children: React.ReactNode;
  overflowVisible?: boolean;
  showBackButton?: boolean;
  removeBottomPadding?: boolean;
  disableHeaderLink?: boolean;
  scrollTopOnEntry?: boolean;
  showHomeButton?: boolean;
  canNotGoBack?: boolean;
  closeAppOnBack?: boolean;
}

export const Page = ({
  children,
  disableHeaderLink,
  overflowVisible,
  showBackButton,
  removeBottomPadding,
  scrollTopOnEntry,
  showHomeButton,
  canNotGoBack,
  closeAppOnBack,
}: Props) => {
  useEffect(() => {
    if (scrollTopOnEntry) window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [scrollTopOnEntry]);

  useEffect(() => {
    if (!isIos) return;

    if (canNotGoBack) SwipeBackPlugin?.disable();
    else SwipeBackPlugin?.enable();
  }, [canNotGoBack]);

  useEffect(() => {
    const handleBackButton = ({ canGoBack }: BackButtonListenerEvent) => {
      if (closeAppOnBack) {
        CapacitorApp.exitApp();
        return;
      }

      if (canNotGoBack) return;

      if (canGoBack) window.history.back();
      else CapacitorApp.exitApp();
    };

    if (isNative) CapacitorApp.addListener('backButton', handleBackButton);
  }, [canNotGoBack, closeAppOnBack]);

  return (
    <Box flex={1} width="100%" overflow={overflowVisible ? 'visible' : 'auto'} position="relative">
      <SafeAreaTopBox />
      <Container maxWidth="md" sx={{ px: 0, pb: removeBottomPadding ? 0 : 10 }}>
        <Header showBackButton={showBackButton} disableHeaderLink={disableHeaderLink} />

        {showBackButton && !isNative && (
          <Box mb={3}>
            <BackButton />
          </Box>
        )}
        {showHomeButton && !isNative && (
          <Box mb={3}>
            <BackToHomePageButton />
          </Box>
        )}

        <Box px={3}>{children}</Box>
      </Container>
    </Box>
  );
};
