import * as SentryCapacitor from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';

import { isDev, isProd, sentryDsnCapacitor, sentryDsnWeb } from '@/config/env';
import { isNative } from '@/utils';

const SentryPlatform = isNative ? SentryCapacitor : SentryReact;

type ErrorHint = Parameters<typeof SentryPlatform.captureException>[1];

export const captureError = (error: unknown, hint?: ErrorHint) => {
  if (!isDev) SentryPlatform.captureException(error, hint);
  // eslint-disable-next-line no-console
  else console.error('Sentry capture error', error);
};

export const initSentry = () => {
  if (!isDev) {
    SentryPlatform.init({
      dsn: isNative ? sentryDsnCapacitor : sentryDsnWeb,
      replaysSessionSampleRate: 0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: isProd ? 1.0 : 0,
      integrations: isProd
        ? [
            SentryReact.browserTracingIntegration(),
            SentryReact.replayIntegration({
              // Additional SDK configuration goes in here, for example:
              maskAllText: true,
              blockAllMedia: true,
            }),
          ]
        : [],
      ignoreErrors: ['Network Error', 'timeout of 30000ms exceeded', 'Request aborted'],
      environment: isProd ? 'production' : 'staging',
      tracesSampleRate: 0.2,
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    });
  }
};
