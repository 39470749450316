import { Device } from '@capacitor/device';
import { useCallback, useEffect } from 'react';

import { useAppSelector } from '@/hooks';

import { convertLanguagesCodesToApp } from './convertLanguagesCodesToApp';
import { useAppTranslation } from './useAppTranslation';

export const useLoadOnceUserAndDeviceLanguage = () => {
  const { i18n } = useAppTranslation();
  const currentUserLanguage = useAppSelector((state) => state.user.currentUser?.language);

  const getCurrentDeviceLanguage = async () => {
    const languageTag = await Device.getLanguageTag();
    return convertLanguagesCodesToApp(languageTag.value);
  };

  const loadLanguageOnce = useCallback(async () => {
    if (!i18n.initializedLanguageOnce) {
      i18n.initializedLanguageOnce = true;
      if (currentUserLanguage) i18n.changeLanguage(currentUserLanguage);
      else i18n.changeLanguage(await getCurrentDeviceLanguage());
    }
  }, [currentUserLanguage, i18n]);

  useEffect(() => {
    loadLanguageOnce();
  }, [loadLanguageOnce]);
};
