import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, IconButton, Modal, Paper } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';

import { useDeviceSize } from '@/hooks';

interface ModalTemplateProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  maxWidth?: number;
  scrollable?: boolean;
  fullScreen?: boolean;
}

const AnimatedPaper = motion(Paper);

export const ModalTemplate = ({
  isOpen,
  onClose,
  children,
  maxWidth = 400,
  scrollable,
  fullScreen,
}: ModalTemplateProps) => {
  const { isMobile } = useDeviceSize();

  const handleOnClose = (_event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;

    onClose?.();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleOnClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          transitionDuration: 300,
        },
      }}
    >
      <div>
        <AnimatePresence mode="wait">
          <AnimatedPaper
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            sx={{
              maxWidth,
              maxHeight: isMobile ? '100dvh' : '90dvh',
              overflow: scrollable ? 'auto' : 'hidden',
              borderRadius: isMobile ? 0 : undefined,
              position: 'relative',
              width: fullScreen ? '100vw' : undefined,
              height: fullScreen ? '100vh' : undefined,
              paddingTop: 'env(safe-area-inset-top)',
            }}
          >
            {!!onClose && (
              <Box display="flex" justifyContent="flex-end" p={[1, 2]}>
                <IconButton onClick={onClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            {children}
          </AnimatedPaper>
        </AnimatePresence>
      </div>
    </Modal>
  );
};
