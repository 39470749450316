import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { Page } from '@/components/layout';
import { useRefreshCurrentUserData } from '@/features/User';
import { isNative } from '@/utils';

import {
  ResumeQuiz,
  QuizCreator,
  RenewPlanBox,
  ReviewMistakesBox,
  ExpiringPlanBox,
  AssistantChatBox,
} from '../../components';
import { useShowPlanBoxes } from '../../hooks/useShowPlanBoxes';

export const HomePage = () => {
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { showExpiringPlanBox, showRenewPlanBox } = useShowPlanBoxes();

  useEffect(() => {
    refreshCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page closeAppOnBack disableHeaderLink={isNative}>
      <Stack spacing={10}>
        {/* Plan sections */}
        {showRenewPlanBox && showExpiringPlanBox && (
          <Stack spacing={4}>
            <RenewPlanBox />
            <ExpiringPlanBox />
          </Stack>
        )}

        <AssistantChatBox />

        {/* Quiz sections */}
        <Stack spacing={4}>
          <ResumeQuiz />
          <ReviewMistakesBox />
          <QuizCreator />
        </Stack>
      </Stack>
    </Page>
  );
};
