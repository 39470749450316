import { axiosInstance } from '@/services';

interface Payload {
  type: 'google' | 'facebook' | 'apple';
  payload:
    | {
        accessToken: string;
      }
    | {
        // For Apple mobile and web only
        code: string;
        type?: 'apple_mobile';
      };
}

interface SsoLoginResponse {
  access: string;
  refresh: string;
}

interface SsoLoginProcessedResponse {
  accessToken: string;
  refreshToken: string;
}

export const ssoLogin = async (data: Payload): Promise<SsoLoginProcessedResponse> => {
  const response = await axiosInstance.post<SsoLoginResponse>(`/dj-rest-auth/${data.type}/`, data.payload, {
    headers: {
      Authorization: undefined,
    },
  });

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh,
  };
};
