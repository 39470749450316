import BackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import HouseIcon from '@mui/icons-material/House';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  IconButton,
  MenuItem,
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  Drawer,
  List,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';

import { useLogout } from '@/features/User';
import { useAppDispatch, useAppNavigate, useIsPage } from '@/hooks';
import { Pages } from '@/navigation';
import { toggleThemeMode as toggleThemeModeAction } from '@/styles/theme';
import { useAppTranslation } from '@/translation';

import { Logo } from '../Logo';

interface Props {
  showBackButton?: boolean;
}

export const HeaderNative = ({ showBackButton }: Props) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const { navigate, back } = useAppNavigate();
  const dispatch = useAppDispatch();

  const [showDrawer, setShowDrawer] = useState(false);

  const { logout } = useLogout();

  const isHomePage = useIsPage(Pages.home);
  const isAssistantChatPage = useIsPage(Pages.assistantChat);
  const isQuizHistoryPage = useIsPage(Pages.history);
  const isUserProfilePage = useIsPage(Pages.userProfile);

  const isDarkMode = palette.mode === 'dark';

  const handleLogout = useCallback(() => logout(), [logout]);

  const toggleThemeMode = useCallback(() => dispatch(toggleThemeModeAction()), [dispatch]);

  const drawerList = (
    <Box
      width={250}
      height="100%"
      role="presentation"
      onClick={() => setShowDrawer(false)}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      paddingTop="env(safe-area-inset-top)"
      paddingBottom="env(safe-area-inset-bottom)"
    >
      <Box display="flex" flexDirection="column">
        <List>
          <MenuItem onClick={() => navigate(Pages.home)} data-test="header_user-menu_home" disabled={isHomePage}>
            <ListItemIcon>
              <HouseIcon />
            </ListItemIcon>
            <ListItemText>{t('common:homePage')}</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => navigate(Pages.assistantChat)}
            data-test="header_user-menu_assistant_chat"
            disabled={isAssistantChatPage}
          >
            <ListItemIcon>
              <AutoAwesomeIcon />
            </ListItemIcon>
            <ListItemText>{t('chat:assistantChat')}</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => navigate(Pages.history)}
            data-test="header_user-menu_history"
            disabled={isQuizHistoryPage}
          >
            <ListItemIcon>
              <HistoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{t('common:quizzesHistory')}</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => navigate(Pages.userProfile)}
            data-test="header_user-menu_profile"
            disabled={isUserProfilePage}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText>{t('common:profile')}</ListItemText>
          </MenuItem>
        </List>
      </Box>

      <List>
        <MenuItem onClick={toggleThemeMode}>
          <ListItemIcon>
            <LightModeIcon />
          </ListItemIcon>
          <ListItemText>{t(isDarkMode ? 'common:lightMode' : 'common:darkMode')}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} data-test="header_user-menu_logout">
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t('user:logout')}</ListItemText>
        </MenuItem>
      </List>
    </Box>
  );

  return (
    <>
      <Drawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          sx: {
            borderRadius: 0,
            padding: [0],
          },
        }}
      >
        {drawerList}
      </Drawer>
      <AppBar
        position="static"
        sx={{
          borderRadius: 0,
          mb: 4,
          backgroundColor: palette.app.background,
          color: palette.app.primary,
          boxShadow: 0,
          px: [4],
          py: [0],
          backgroundImage: 'none',
        }}
      >
        <Toolbar sx={{ p: 0, py: 0 }}>
          {showBackButton && (
            <IconButton size="large" edge="start" color="inherit" aria-label="back" onClick={back}>
              <BackIcon fontSize="large" />
            </IconButton>
          )}
          <Box
            flexGrow={1}
            alignItems="center"
            display="flex"
            justifyContent="center"
            mx={2}
            ml={showBackButton ? 0 : 12}
          >
            <Logo size="small" linkToHome />
          </Box>
          <IconButton size="large" edge="end" color="inherit" aria-label="menu" onClick={() => setShowDrawer(true)}>
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};
