import { NavigationBar } from '@capgo/capacitor-navigation-bar';
import { CssBaseline, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';
import { isNative } from '@/utils';

import { darkModePalette, lightModePalette } from './colorThemeHandler';
import { muiComponentOverwrite } from './muiComponentOverwrite';
import { setThemeMode } from './themeSlice';
import { typography } from './typographyThemeHandler';

interface MaterialThemeProviderProps {
  children: ReactNode;
}

export const MaterialThemeProvider = ({ children }: MaterialThemeProviderProps) => {
  const isDemoPage = useMatch(Pages.demo);
  const dispatch = useAppDispatch();
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');
  const themeMode = useAppSelector((state) => state.theme.mode);

  // when themeMode is system update the themeMode based on the prefersLightMode
  useEffect(() => {
    if (themeMode === 'system') dispatch(setThemeMode(prefersLightMode ? 'light' : 'dark'));
  }, [prefersLightMode, themeMode, dispatch]);

  const theme = useMemo(() => {
    const getTheme = (): 'dark' | 'light' => {
      if (themeMode === 'system') return prefersLightMode ? 'light' : 'dark';

      return themeMode;
    };

    const currentPalette = isDemoPage || getTheme() === 'dark' ? darkModePalette : lightModePalette;

    if (isDemoPage && currentPalette.background) currentPalette.background.default = 'transparent';

    return createTheme({
      palette: currentPalette,
      typography,
      spacing: 4,
      components: muiComponentOverwrite(currentPalette),
    });
  }, [themeMode, isDemoPage, prefersLightMode]);

  const setNavigationBarColor = useCallback(() => NavigationBar.setNavigationBarColor({ color: '#0F0F0F' }), []);

  // Set the navigation bar color to the background color of the theme, especially for android gesture navigation bar
  useEffect(() => {
    if (!isNative) return;

    setNavigationBarColor();

    // Workaround - setNavigationBarColor is not working on the first render
    setTimeout(() => {
      setNavigationBarColor();
    }, 500);
  }, [setNavigationBarColor]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
