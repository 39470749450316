import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AppUrlListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.com').pop();
      if (slug) navigate(slug);

      // If no match, do nothing - let regular routing
      // logic take over
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
