import { isProd } from '@/config';
import { AppleOAuthProviderProps } from './types';
import { useEffect, useState } from 'react';

export const AppleOAuthProvider = ({ children }: AppleOAuthProviderProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!scriptLoaded) return;

    window.AppleID?.auth?.init({
      clientId: isProd ? 'com.refereewise.app.web.signin' : 'com.refereewise.app.staging.web.signin',
      scope: 'name email',
      redirectURI: `https://${isProd ? 'app' : 'staging'}.refereewise.com/accounts/apple/login/callback/`,
      usePopup: true,
    });
  }, [scriptLoaded]);

  return <>{children}</>;
};
