import { ReactNode, useEffect } from 'react';

import { useAppSelector, useAppNavigate } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { Pages } from './pages';

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { t } = useAppTranslation();
  const { session } = useAppSelector((state) => state.user);
  const { navigate } = useAppNavigate();

  useEffect(() => {
    if (!session) {
      const from = window.location.pathname !== '/' ? window.location.pathname + window.location.search : undefined;
      navigate(Pages.login, {}, from ? { from } : undefined);
    }
  }, [navigate, session, t]);

  return <>{children}</>;
};
