import { StatusBar, Style } from '@capacitor/status-bar';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

import { useAppSelector } from '@/hooks';
import { isNative } from '@/utils';

export const SafeAreaTopBox = () => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const { palette } = useTheme();

  useEffect(() => {
    if (isNative) StatusBar.setStyle({ style: themeMode === 'dark' ? Style.Dark : Style.Light });
  }, [themeMode]);

  return (
    <Box
      sx={{
        bgcolor: palette.background.default,
        height: 'env(safe-area-inset-top)',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000,
      }}
    />
  );
};
