import { SocialLogin } from '@capgo/capacitor-social-login';
import { useEffect } from 'react';

import { AppleOAuthProviderProps } from './types';
import { isIos } from '@/utils';

export const AppleOAuthProvider = ({ children }: AppleOAuthProviderProps) => {
  useEffect(() => {
    if (!isIos) return;

    SocialLogin.initialize({
      apple: {},
    });
  }, []);

  return children;
};
