import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAppDispatch, useAppSelector, useNotify } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { useUpdateCurrentUserApi } from '../api';
import { setCurrentUserData } from '../store';

const formSchema = z.object({
  firstName: z.string().min(2).max(150),
  lastName: z.string().min(2).max(150),
});

type UserProfileFormValues = z.infer<typeof formSchema>;

export const useUserProfileForm = () => {
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.user?.currentUser);
  const { updateCurrentUser } = useUpdateCurrentUserApi();

  const form = useForm<UserProfileFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
    },
  });
  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<UserProfileFormValues> = async (data) => {
    if (!currentUser?.id) return null;

    const results = await updateCurrentUser({
      userId: currentUser.id,
      ...data,
    });

    if (results) {
      dispatch(
        setCurrentUserData({
          ...currentUser,
          firstName: data.firstName,
          lastName: data.lastName,
        }),
      );
      notify('success', t('user:successfullyUpdatedYourAccountData'));
      form.reset({
        firstName: data.firstName,
        lastName: data.lastName,
      });
    }
  };

  const onSubmitPress = handleSubmit(onSubmit);

  const isFormEdited = form.formState.isDirty;

  return {
    form,
    onSubmitPress,
    isFormEdited,
  };
};
