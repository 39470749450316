import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { isDev } from '@/config';

import { useSSOLogin } from '../../hooks';

import { SSOButton } from './SSOButton';
import { isIos, isWeb } from '@/utils';

interface SSOLoginProps {
  type: 'login' | 'registration';
  onStateChange: (isLoading: boolean) => void;
  disabled: boolean;
}

export const SSOLogin = ({ type, onStateChange, disabled }: SSOLoginProps) => {
  const { loginUsingGoogle, isLoading, loginUsingApple } = useSSOLogin();

  useEffect(() => {
    onStateChange(isLoading);
  }, [isLoading, onStateChange]);

  return (
    <Box justifyContent="center" alignContent="center" alignItems="center" width="100%" maxWidth={300}>
      {/* Source: https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid#get_your_google_api_client_id */}
      {isDev && (
        <Helmet>
          <meta name="referrer" content="no-referrer-when-downgrade" />
        </Helmet>
      )}
      <Stack spacing={4}>
        <SSOButton provider="google" type={type} onClick={loginUsingGoogle} disabled={isLoading || disabled} />
        {(isIos || isWeb) && (
          <SSOButton provider="apple" type={type} onClick={loginUsingApple} disabled={isLoading || disabled} />
        )}
      </Stack>
    </Box>
  );
};
