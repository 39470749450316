import { Box, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { InTextButton, Text } from '@/components/elements';
import { Pages, useGetPagePathWithLang } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { CookiesTypes, acceptCookiesConsent, getCookiesConsent } from '../utils/cookiesConsent';

import { CookiesSettingsGroup } from './CookiesSettingsGroup';
import { Cookie } from './SingleCookie';

interface CookiesSettingsProps {
  allowToBack: boolean;
  onDismiss: (closePrompt: boolean) => void;
}

const cookiesNecessary: Cookie[] = [
  {
    name: 'cookieConsent',
    duration: {
      years: 1,
    },
    description: 'common:cookieCookieConsentDescription',
    service: 'RefereeWise',
  },
  {
    name: 'language',
    service: 'RefereeWise',
    duration: {
      years: 1,
    },
    description: 'common:cookieLanguageDescription',
  },
  {
    name: 'matomo_sessid',
    service: 'RefereeWise',
    duration: {
      days: 14,
    },
    description: 'common:cookiematomo_sessidDescription',
  },
];

const cookiesAnalytics: Cookie[] = [
  {
    name: '_ga_#',
    duration: {
      years: 1,
      months: 1,
      days: 4,
    },
    description: 'common:cookie_ga_#Description',
    service: 'Google Analytics',
  },
  {
    name: '_ga',
    duration: {
      years: 1,
      months: 1,
      days: 4,
    },
    description: 'common:cookie_gaDescription',
    service: 'Google Analytics',
  },
  {
    name: '_pk_id',
    duration: {
      years: 1,
      months: 1,
    },
    description: 'common:cookie_gaDescription',
    service: 'RefereeWise',
  },
  {
    name: '_pk_ref',
    duration: {
      years: 1,
      months: 1,
    },
    description: 'common:cookie_pk_refDescription',
    service: 'RefereeWise',
  },
  {
    name: '_pk_ses, _pk_cvar, _pk_hsr',
    duration: {
      hours: 0.5,
    },
    description: 'common:cookie_pk_sesDescription',
    service: 'RefereeWise',
  },
];

const cookiesAdvert: Cookie[] = [
  {
    name: '_fbp',
    duration: {
      months: 6,
    },
    description: 'common:cookie_fbpDescription',
    service: 'Facebook Pixel',
  },
  {
    name: 'fr',
    duration: {
      months: 3,
    },
    description: 'common:cookiefrDescription',
    service: 'Facebook Pixel',
  },
];

export const CookiesSettings = ({ allowToBack, onDismiss }: CookiesSettingsProps) => {
  const { t } = useAppTranslation();
  const cookiesPolicyPath = useGetPagePathWithLang(Pages.cookiesPolicy);
  const privacyPolicyPath = useGetPagePathWithLang(Pages.privacyPolicy);
  const [selectedCookies, setSelectedCookies] = useState<CookiesTypes>({
    analytics: false,
    advert: false,
  });

  useEffect(() => {
    const cookiesConsent = getCookiesConsent();
    if (!cookiesConsent) return;

    setSelectedCookies({
      analytics: cookiesConsent.analytics,
      advert: cookiesConsent.advert,
    });
  }, []);

  const acceptAllCookies = () => {
    acceptCookiesConsent({
      analytics: true,
      advert: true,
    });
    onDismiss(true);
  };

  const acceptSelectedCookies = () => {
    acceptCookiesConsent(selectedCookies);
    onDismiss(true);
  };

  const togglePerformance = () => {
    setSelectedCookies((prevState) => ({
      ...prevState,
      analytics: !prevState.analytics,
    }));
  };

  const toggleAdvert = () => {
    setSelectedCookies((prevState) => ({
      ...prevState,
      advert: !prevState.advert,
    }));
  };

  return (
    <>
      <Stack spacing={6} maxHeight={500} sx={{ overflowY: 'scroll' }}>
        <Text variant="bodyMedium" translationKey="common:cookiesSettingsPart1" />
        <Box mt={2} lineHeight={1}>
          <Text variant="bodyMedium" translationKey="common:cookiesSettingsPart2" />
          <InTextButton href={cookiesPolicyPath} target="_blank" labelId="common:cookiesSettingsPart3" />
          <Text variant="bodyMedium" translationKey="common:cookiesSettingsPart4" />
          <InTextButton href={privacyPolicyPath} target="_blank" labelId="common:cookiesSettingsPart5" />
        </Box>

        <Stack spacing={1}>
          {/* <Switch labelKey="common:cookiesNecessaryForOperationOfWebsite" checked={true} disabled /> */}
          <CookiesSettingsGroup
            checked={true}
            disabled
            label="common:cookiesNecessaryForOperationOfWebsite"
            description="common:cookiesNecessaryForOperationOfWebsiteDescription"
            cookies={cookiesNecessary}
          />
          <CookiesSettingsGroup
            checked={selectedCookies.analytics}
            onChange={togglePerformance}
            label="common:analyticsCookies"
            description="common:analyticsCookiesDescription"
            cookies={cookiesAnalytics}
          />
          <CookiesSettingsGroup
            checked={selectedCookies.advert}
            onChange={toggleAdvert}
            label="common:advertCookiesTitle"
            description="common:advertCookiesDescription"
            cookies={cookiesAdvert}
          />
        </Stack>
      </Stack>

      <Stack spacing={[1, 4]} direction="row">
        <Button
          size="small"
          variant="text"
          onClick={() => onDismiss(false)}
          className="text-on-surface-variant font-normal"
        >
          {allowToBack ? t('common:back') : t('common:cancel')}
        </Button>
        <Button size="small" variant="text" onClick={acceptAllCookies} className="text-on-surface-variant font-normal">
          {t('common:iAcceptAllCookies')}
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          sx={{ wordWrap: 'normal', whiteSpace: 'normal', p: [1] }}
          onClick={acceptSelectedCookies}
        >
          {t('common:iConfirmMySelection')}
        </Button>
      </Stack>
    </>
  );
};
