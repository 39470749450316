import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Text } from '@/components/elements';
import { zIndex } from '@/constants/zIndex';
import { TranslationKeys } from '@/translation';
import { isNative } from '@/utils';

import { shouldShowCookiesConsent } from '../utils/cookiesConsent';

import { CookiesBasics } from './CookiesBasics';
import { CookiesSettings } from './CookiesSettings';
import { CookiesConsentContainer } from './styled';

export const CookiesConsent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const showCookiesSettingsParam = searchParams.get('showCookiesSettings');

  const [showCookiesPrompt, setShowCookiesPrompt] = useState(false);
  const [showCookiesSettings, setShowCookiesSettings] = useState(false);

  useEffect(() => {
    const checkAndShowCookiesPrompt = async () => {
      const shouldShow = await shouldShowCookiesConsent();
      setShowCookiesPrompt(shouldShow);
    };

    checkAndShowCookiesPrompt();
  }, []);

  useEffect(() => {
    if (showCookiesSettingsParam) {
      setShowCookiesSettings(true);
      searchParams.delete('showCookiesSettings');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, showCookiesSettingsParam]);

  const closeSettings = (closePrompt: boolean) => {
    if (closePrompt) setShowCookiesPrompt(false);
    setShowCookiesSettings(false);
  };

  const showSettings = () => {
    setShowCookiesSettings(true);
  };

  const onCloseBasics = () => {
    setShowCookiesPrompt(false);
  };

  if (!showCookiesPrompt && !showCookiesSettings) return null;

  const titleKey: TranslationKeys = showCookiesSettings ? 'common:cookiesSettings' : 'common:weCareAboutPrivacy';

  return (
    <Box
      zIndex={zIndex.cookiesConsent}
      position="fixed"
      bottom={[5, 25]}
      left={[5, 25]}
      right={[5, 25]}
      maxWidth={800}
      data-test="cookies-consent-banner"
    >
      <CookiesConsentContainer>
        <Stack spacing={4} pb={isNative ? 4 : 0}>
          <Box>
            <Text variant="titleLarge">{'🍪 '}</Text>
            <Text variant="titleLarge" translationKey={titleKey} />
          </Box>

          {showCookiesPrompt && !showCookiesSettings && (
            <CookiesBasics showSettings={showSettings} onClose={onCloseBasics} />
          )}
          {showCookiesSettings && <CookiesSettings onDismiss={closeSettings} allowToBack={showCookiesPrompt} />}
        </Stack>
      </CookiesConsentContainer>
    </Box>
  );
};
