import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ErrorBoundary } from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { SessionManager } from '@/features/User/components';
import { router } from '@/navigation/router';
import {
  ReactQueryClientProvider,
  AxiosInterceptorsProvider,
  setReduxStoreForAxios,
  initSentry,
  captureError,
  trackEvent,
} from '@/services';
import { store } from '@/store';

import { updateServicesBasedOnConsent } from './features/CookiesConsent/utils/cookiesConsent';
import { HeadHelmet } from './features/SEO';
import { GoogleOAuthProvider } from './features/User/components/GoogleOAuthProvider';
import { getGoogleTagManagerConfig } from './services/googleTagManager/googleTagManager';
import { useConfigDayjsLang } from './translation';
import { AppleOAuthProvider } from './features/User/components/AppleOAuthProvider';

setReduxStoreForAxios(store);

const messagesOnWhichToReload = ['load component', 'imported module'];

export const App = () => {
  useConfigDayjsLang();

  useEffect(() => {
    updateServicesBasedOnConsent();
    initSentry();
    trackEvent.user.setPlatform();
  }, []);

  const errorHandler = (error: Error) => {
    const message = error.message.toLowerCase();

    captureError(error);
    // OLD CODE: keep it for now, but it will be removed soon
    // Reload the window to fetch new chunks
    if (messagesOnWhichToReload.some((msg) => message.includes(msg))) window.location.reload();
  };

  return (
    <ErrorBoundary onError={errorHandler}>
      <GTMProvider state={getGoogleTagManagerConfig()}>
        <GoogleOAuthProvider>
          <AppleOAuthProvider>
            <HeadHelmet />
            <ReduxProvider store={store}>
              <SnackbarProvider>
                <ReactQueryClientProvider>
                  <AxiosInterceptorsProvider store={store}>
                    <SessionManager>
                      <RouterProvider router={router} />
                    </SessionManager>
                  </AxiosInterceptorsProvider>
                </ReactQueryClientProvider>
              </SnackbarProvider>
            </ReduxProvider>
          </AppleOAuthProvider>
        </GoogleOAuthProvider>
      </GTMProvider>
    </ErrorBoundary>
  );
};
