import { Store } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { ReactNode, useEffect } from 'react';

import { useLogout, useRefreshToken } from '@/features/User';
import { useAppSelector, useNotify } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { captureError } from '../sentry';

import { axiosInstance } from './axios';

interface AxiosInterceptorsProviderProps {
  store: Store;
  children: ReactNode;
}

type APIError = AxiosError & {
  config: AxiosRequestConfig & {
    _retry?: boolean;
  };
};

const standardErrorMsgKey = 'common:errorOccurredTryAgain';

const getResponseErrorsMessageKey = (errorRequest: APIError) => {
  switch (errorRequest.code) {
    case AxiosError.ECONNABORTED:
      return null;
    case AxiosError.ETIMEDOUT:
    case AxiosError.ERR_NETWORK:
      return 'common:connectionErrorTryAgain';
    default:
      break;
  }

  return standardErrorMsgKey;
};

export const AxiosInterceptorsProvider = ({ store, children }: AxiosInterceptorsProviderProps) => {
  const { refreshToken } = useRefreshToken();
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const { logout } = useLogout();
  const { session } = useAppSelector((state) => state.user);

  const isCurrentSessionExist = session !== null;
  const axiosResponse = axiosInstance.interceptors.response;

  useEffect(() => {
    const responseInterceptor = async (response: AxiosResponse) => {
      return response;
    };

    const errorInterceptor = async (error: APIError) => {
      try {
        const originalRequest = error.config;
        const httpCode = get(error, 'response.status');

        if (
          httpCode === 401 &&
          !originalRequest._retry &&
          originalRequest.url &&
          !originalRequest.url.endsWith('refresh')
        ) {
          originalRequest._retry = true;

          // INFO: Internal refresh token mechanism for both platforms
          const accessToken = await refreshToken();
          if (accessToken) {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axiosInstance(originalRequest);
          }

          logout(isCurrentSessionExist);
          throw error;
        } else if (httpCode === 401) {
          logout(isCurrentSessionExist);
          throw error;
        }

        if (httpCode !== 400) {
          const errorKey = getResponseErrorsMessageKey(error);

          if (errorKey === null) return;

          notify('error', t(errorKey));

          if (standardErrorMsgKey === errorKey) {
            captureError({
              httpCode,
              errorCode: error.code,
              errorMessage: error.message,
              errorStack: error.stack,
              requestBody: JSON.stringify(error.config.data),
              response: JSON.stringify(error.response?.data),
            });
          }
        }

        throw error;
      } catch (error) {
        captureError(error);
        logout(isCurrentSessionExist);
        throw error;
      }
    };

    const responseInterceptors = axiosResponse.use(responseInterceptor, errorInterceptor);

    return () => {
      axiosResponse.eject(responseInterceptors);
    };
  }, [axiosResponse, isCurrentSessionExist, logout, notify, refreshToken, store, t]);

  return <>{children}</>;
};
