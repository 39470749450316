import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAppNavigate, useNotify } from '@/hooks';
import { Pages } from '@/navigation';
import { StandardGtmEvent, captureError, trackEvent, trackGTMEvent } from '@/services';
import { TranslationFunction, TranslationKeys, useAppTranslation } from '@/translation';

import { register } from '../api';

const formSchema = (t: TranslationFunction) =>
  z.object({
    email: z.string().email(),
    password: z
      .string()
      .min(8)
      .refine((value) => !/^\d+$/.test(value), t('user:passwordCannotBeOnlyNumbers')),
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    terms: z.boolean().refine((value) => value === true, t('user:termsAndConditionsNotAccepted')),
  });

type RegistrationFormValues = z.infer<ReturnType<typeof formSchema>>;

const defaultValues: RegistrationFormValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  terms: false,
};

export const useRegistrationForm = () => {
  const { notify } = useNotify();
  const { t, isTranslationExist, currentLanguage } = useAppTranslation();
  const { navigate } = useAppNavigate();

  const form = useForm<RegistrationFormValues>({
    defaultValues,
    resolver: zodResolver(formSchema(t)),
  });

  const { handleSubmit, formState } = form;

  const onSubmit: SubmitHandler<RegistrationFormValues> = async (data) => {
    try {
      const { id, email } = await register({
        ...data,
        hasTermsAndConditionsAccepted: data.terms,
        language: currentLanguage,
      });

      if (!id) {
        notify('error', t('user:registrationFailedForUnknownReason'));
        return;
      }

      trackGTMEvent(StandardGtmEvent.signUp, {
        userId: id,
        authenticationMethod: 'email',
      });
      trackEvent.user.registration('email');
      trackEvent.user.setUserId(id);
      navigate(Pages.registrationSuccess, {}, { email });
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;
        const { username, password } = response?.data || {};
        const knownError = username?.[0] || password?.[0];

        if (knownError && isTranslationExist(`user:form.${knownError}` as TranslationKeys)) {
          notify('error', t([`user:form.${knownError}` as TranslationKeys, 'user:registrationFailedForUnknownReason']));
          return;
        }

        notify('error', t('user:registrationFailedForUnknownReason'));
        captureError({
          message: err.message,
          response: {
            username,
            paswd: password,
          },
        });
      }
    }
  };

  const onSubmitPress = handleSubmit(onSubmit);

  const isSubmitting = formState.isSubmitting;

  return {
    form,
    onSubmitPress,
    isSubmitting,
  };
};
