import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAppSelector, useNotify } from '@/hooks';
import { captureError } from '@/services';
import { useAppTranslation } from '@/translation';

import { changePassword } from '../api';

const formSchema = z.object({
  // currentPassword: z.string().min(8).max(150),
  newPassword: z.string().min(8).max(150),
  repeatNewPassword: z.string().min(8).max(150),
});

type ChangePasswordFormValues = z.infer<typeof formSchema>;

export const useChangePasswordForm = () => {
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const currentUser = useAppSelector((state) => state.user?.currentUser);

  const form = useForm<ChangePasswordFormValues>({
    resolver: zodResolver(
      formSchema.refine((data) => data.newPassword === data.repeatNewPassword, {
        message: t('user:passwordsDoNotMatch'),
        path: ['repeatNewPassword'],
      }),
    ),
  });
  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<ChangePasswordFormValues> = async (data) => {
    try {
      if (!currentUser?.id) return null;

      const results = await changePassword({
        userId: currentUser.id,
        newPassword: data.newPassword,
      });

      if (results) {
        notify('success', t('user:successfullyChangedPassword'));
        form.reset();
      }
    } catch (err: unknown) {
      notify('error', t('common:errorOccurredTryAgain'));
      captureError(err);
    }
  };

  const onSubmitPress = handleSubmit(onSubmit);

  const isFormEdited = form.formState.isDirty;

  return {
    form,
    onSubmitPress,
    isFormEdited,
  };
};
