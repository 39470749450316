import { SplashScreen } from '@capacitor/splash-screen';
import EmailIcon from '@mui/icons-material/Email';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { createSearchParams } from 'react-router-dom';

import { ControlledPasswordField, ControlledTextField } from '@/components/form';
import { Meta } from '@/components/layout';
import { useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { LoginPageTemplate, SSOLogin } from '../../components';
import { useNavigateFromLogin, useLoginForm } from '../../hooks';

export const LoginPage = () => {
  const { t } = useAppTranslation();
  const { session } = useAppSelector((state) => state.user);
  const { navigateToHomeOrFrom } = useNavigateFromLogin();

  const [isSSOLoading, setIsSSOLoading] = useState(false);

  useEffect(() => {
    if (session) navigateToHomeOrFrom();
    else SplashScreen.hide();
  }, [navigateToHomeOrFrom, session]);

  const { form, onSubmitPress, isSubmitting } = useLoginForm();

  const providedEmail = form.watch('email');
  const isLoading = isSSOLoading || isSubmitting;

  const recoverPasswordLink = useMemo(() => {
    if (!providedEmail) return Pages.recoverPassword;

    const searchParams = createSearchParams({ email: providedEmail });

    return `${Pages.recoverPassword}?${searchParams.toString()}`;
  }, [providedEmail]);

  return (
    <LoginPageTemplate closeAppOnBack headerMessageKey="user:welcomeBack">
      <Meta titleKey="user:signIn" />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => onSubmitPress())}>
          <Stack spacing={4} mt={4} mb={8}>
            <ControlledTextField
              id="email"
              name="email"
              type="email"
              label={t('user:emailAddress')}
              autoComplete="email"
              autoCapitalize="off"
              margin="normal"
              fullWidth
              alwaysShowLabel
            />
            <ControlledPasswordField
              id="password"
              name="password"
              label={t('user:password')}
              autoComplete="current-password"
            />
          </Stack>
          <Stack alignItems="center" spacing={6}>
            <LoadingButton
              type="submit"
              itemType="submit"
              variant="contained"
              sx={{ maxWidth: 300 }}
              fullWidth
              onClick={onSubmitPress}
              loading={isLoading}
              startIcon={<EmailIcon />}
              data-test="login-submit"
            >
              <Box ml={1}>{t('user:signInUsingEmail')}</Box>
            </LoadingButton>
            <SSOLogin type="login" onStateChange={setIsSSOLoading} disabled={isSubmitting} />
          </Stack>
          <Stack mt={6} spacing={2} alignItems="center">
            <Button href={recoverPasswordLink} variant="text" color="secondary" sx={{ textTransform: 'none' }}>
              {t('user:recoverPassword')}
            </Button>
            <Button href={Pages.registration} variant="text" color="secondary" sx={{ textTransform: 'none' }}>
              {t('user:doNotHaveAccountSignUp')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </LoginPageTemplate>
  );
};
