import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAppNavigate, useNotify } from '@/hooks';
import { Pages } from '@/navigation';
import { captureError } from '@/services';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { resetPassword } from '../api';

const formSchema = z.object({
  newPassword: z.string().min(8),
});

type ResetPasswordFormValues = z.infer<typeof formSchema>;

interface UseResetPasswordFormParams {
  token: string;
  onLinkExpired: () => void;
}

export const useResetPasswordForm = ({ token, onLinkExpired }: UseResetPasswordFormParams) => {
  const { notify } = useNotify();
  const { t, isTranslationExist } = useAppTranslation();
  const { navigate } = useAppNavigate();

  const form = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit: SubmitHandler<ResetPasswordFormValues> = async (data) => {
    try {
      const { ...payload } = data;
      const result = await resetPassword({
        ...payload,
        token,
      });

      if (result) {
        notify('success', t('user:passwordSuccessfullyChanged'));
        navigate(Pages.login);
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;
        const { nonFieldErrors, password } = response?.data || {};

        if (nonFieldErrors?.includes('LINK_NOT_VALID')) {
          onLinkExpired();
          return;
        }

        const knownError = password?.[0];

        if (knownError && isTranslationExist(`user:form.${knownError}` as TranslationKeys)) {
          notify('error', t([`user:form.${knownError}` as TranslationKeys, 'common:errorOccurredTryAgain']));
          return;
        }

        notify('error', t('common:errorOccurredTryAgain'));
        captureError(err);
      }
    }
  };

  const onSubmitPress = handleSubmit(onSubmit);

  const isSubmitting = formState.isSubmitting;

  return {
    form,
    onSubmitPress,
    isSubmitting,
  };
};
