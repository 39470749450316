import { isNative } from '@/utils';

import { UseSSOLogin } from './types';
import { useSSOLogin as nativeModule } from './useSsoLogin.native';
import { useSSOLogin as webModule } from './useSsoLogin.web';

export const useSSOLogin: UseSSOLogin = () => {
  if (isNative) return nativeModule();
  else return webModule();
};
