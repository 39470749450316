import { SocialLogin } from '@capgo/capacitor-social-login';
import { useEffect } from 'react';

import { GoogleOAuthProviderProps } from './types';
import { isAndroid, isIos, isWeb } from '@/utils';
import {
  googleOAuthClientIdAndroid,
  googleOAuthClientIdIosDev,
  googleOAuthClientIdIosProd,
  googleOAuthClientIdIosStaging,
  googleOAuthClientIdWeb,
  isDev,
  isProd,
} from '@/config';

const getGoogleOAuthClientId = () => {
  if (isAndroid) return googleOAuthClientIdAndroid;

  if (isIos) {
    if (isProd) return googleOAuthClientIdIosProd;
    if (isDev) return googleOAuthClientIdIosDev;
    return googleOAuthClientIdIosStaging;
  }

  return googleOAuthClientIdWeb;
};

export const GoogleOAuthProvider = ({ children }: GoogleOAuthProviderProps) => {
  useEffect(() => {
    SocialLogin.initialize({
      google: {
        webClientId: (isAndroid || isWeb) && getGoogleOAuthClientId(),
        iOSClientId: isIos && getGoogleOAuthClientId(),
        iOSServerClientId: isIos && getGoogleOAuthClientId(),
        mode: 'online',
      },
    });
  }, []);

  return children;
};
